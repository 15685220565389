import { commands } from "../config/commands";

export const Help = () => (
  <div className="content">
    {Object.keys(commands).map((c, i) => (
      <div key={i}>
        <b>{c}:</b> <span className="">{commands[c].description}</span>
      </div>
    ))}
  </div>
);
