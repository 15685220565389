import axios from "axios";

export const sendMessage = async (params) => {
  try {
    const res = await axios.post("messages", params);
    return res.status === 200;
  } catch {
    return false;
  }
};
