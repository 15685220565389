import React from "react";
import { projects } from "../../config/data";

export const Project = () => {
  return (
    <>
      <table border="1" className="content spaced-table">
        <thead className="descriptor">
          <tr>
            <th>Title</th>
            <th>Type</th>
            <th>School</th>
            <th>Duration</th>
            <th>Technologies</th>
          </tr>
        </thead>
        {projects.map((p, i) => (
          <React.Fragment key={i}>
            <tbody>
              <tr>
                <td>{p.hasOwnProperty('link') ? <a target="_blank" href={p.link} class="default-color">{p.title} </a> : p.title}</td>
                <td>{p.type}</td>
                <td>{p.school}</td>
                <td>{p.duration}</td>
                <td>{p.technologies}</td>
              </tr>
            </tbody>
          </React.Fragment>
        ))}
      </table>
    </>
  );
};
