import React from "react";
import { skills } from "../../config/data";

export const Skill = ({}) => {
  return (
    <>
      <table border="0" className="content">
        {skills.map((s, i) => (
          <React.Fragment key={i}>
            <tbody>
              <tr>
                <td className="row-descriptor descriptor">
                  {s.key}
                </td>
                <td>{s.value}</td>
              </tr>
            </tbody>
          </React.Fragment>
        ))}
      </table>
    </>
  );
};
