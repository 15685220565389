import React from "react";
import { education } from "../../config/data";

export const Education = ({}) => {
  return (
    <>
      <table border="3" className="content">
        <thead className="descriptor">
          <tr>
            <th>Level</th>
            <th>Subject</th>
            <th>School</th>
            <th>Location</th>
          </tr>
        </thead>
        {education.map((e, i) => (
          <React.Fragment key={i}>
            <tbody>
              <tr>
                <td>{e.level}</td>
                <td>{e.subject}</td>
                <td>{e.school}</td>
                <td>{e.location}</td>
              </tr>
            </tbody>
          </React.Fragment>
        ))}
      </table>
    </>
  );
};
