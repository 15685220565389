import { Education } from "../commandHandler/cvCommands/Education";
import { Experience } from "../commandHandler/cvCommands/Experience";
import { Project } from "../commandHandler/cvCommands/Project";
import { Skill } from "../commandHandler/cvCommands/Skills";

export const commands = {
  experience: {
    component: Experience,
    description:
    "Employment history and major highlights",
  },
  skills: {
    component: Skill,
    description:
      "Categorized set of skills",
  },
  projects: {
    component: Project,
    description: "Academic/personal projects",
  },
  education: {
    component: Education,
    description:
      "Degrees attained and ongoing",
  },
  whoareyou: {
    component: <div className="content">Skilled <b>software engineer</b> with nearly 5 years of professional experience in <b>end-to-end development</b> while championing best practices, improved processes, clean code, and cleaner architecture.  Hands-on abilities in front-end, back-end, and service implementation specializing in <b>C#, .NET, ASP .NET Core, and Entity Framework, as well as MS SQL, REST, React, and OAuth2.</b> Demonstrated abilities in collaboration, communication, critical thinking, and creative problem solving, with relentless drive to grow continuously and add value consistently</div>,
    description: "A whoareyou version of whoami",
  },
  clear: {
    description: "Clear the console",
  },
};
