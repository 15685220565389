import React from "react";
import { ReactTerminal, TerminalContextProvider } from "react-terminal";
import { contact } from "../commandHandler/cvCommands/Contact";
import { Help } from "../commandHandler/Help";
import { commands } from "../config/commands";

export default function Terminal() {
  const formattedCommands = {};

  Object.keys(commands)
  .map(
    (x) => (formattedCommands[x] = commands[x].component)
  );

  formattedCommands.help = <Help />;

  return (
    <div className="terminalContainer">
      <TerminalContextProvider>
        <ReactTerminal
          commands={formattedCommands}
          theme="matrix"
          prompt="$"
          showControlBar={false}
          welcomeMessage={
            <div className="content welcome">
              <b>Sirjan Sharma</b>
              <br />
              <a className="default-color" href="mailto:sirjan.sharma@outlook.com">sirjan.sharma@outlook.com</a>
              <br />
              <a target="_blank" className="default-color" href="https://www.linkedin.com/in/ssirjann">Linkedin</a>
              <br />
              Type <b className="">help</b> to see all available commands
              <br />
            </div>
          }
          defaultHandler={contact}
        />
      </TerminalContextProvider>
    </div>
  );
}
