import { experience } from "../../config/data";

export const Experience = () => {
  return (
    <div className="content">
      {experience.reverse().map((e, i) => (
        <div key={i} className="section">
          <p className="header">
            {e.company}, __<i>{e.title}</i>{" "}
          </p>
          <p>{e.Duration}</p>
          {e.highlights && (
            <ul>
              {e.highlights.map((e, i) => (
                <li key={i}>{e}</li>
              ))}
            </ul>
          )}
          <b>Technology Used: {e.technologies}</b>
        </div>
      ))}
    </div>
  );
};
