import { sendMessage } from "../../services/contact";
import { object, string } from "yup";

var errorMessage = (
  <div className="content error">
    Oops! Looks like you spoke a language not understood in the Sirjan world.{" "}
    <br />
    Type <i>help</i> to see a list of available commands
  </div>
);

export const contact = (command, attr) => {
  if (command !== "contact") return errorMessage;
  const params = {};

  attr
    .split(/\s+(?=(?:[^']*'[^']*'[^']*)*$)/)
    .filter((x) => x)
    .forEach((x) => {
      let [command, value] = x.split("='");
      value = value.slice(0, -1);
      params[command] = value;
    });

  const validation = validate(params);
  if (validation.success) {
    sendMessage(params);
    return <div className="content">Message sent ✔</div>;
  } else {
    return (
      <div className="content error">
        Please correct the following errors: <br />
        Enter data in the format: <br />
        <i className="header">
          contact name='YOUR_NAME' title='MESSAGE_TITLE' body='MESSAGE_BODY'
          email='YOUR@EMAIL.TLD' [phone='0123456789']{" "}
        </i>
        <ul>
          {validation.errors.map((e, i) => (
            <li key={i}>{e}</li>
          ))}
        </ul>
      </div>
    );
  }
};

const validate = (params) => {
  let contactSchema = object({
    title: string().required().min(3),
    body: string().required().min(10),
    email: string().email().required(),
    name: string().required().min(4),
    phone: string()
      .length(10)
      .matches(/^\d+$/, { message: "phone should only contain digits" }),
  });

  try {
    contactSchema.validateSync(params, { abortEarly: false });
    return { success: true };
  } catch (err) {
    return { success: false, errors: err.inner.flatMap((e) => e.errors) };
  }
};
