const data = {
  projects: [
    {
      title: "Property Mamagement System",
      type: "Group",
      school: "MIU",
      technologies: "Spring boot, react",
      duration: "1 week",
    },
    {
      title: "Portfolio Website",
      type: "Individual",
      school: "MIU",
      technologies: "s3, sns, sqs, lambda, nextjs",
      duration: "1 week",
    },
    {
      title: "GRE Vocab Review",
      type: "Individual",
      school: "n/a",
      technologies: "react native",
      duration: "2 weeks",
      link: "https://github.com/ssirjann/gre-vocab-review"
    },
  ],
  experience: [
    {
      title: "Software Engineer",
      company: "NexSewa",
      Duration: "Jan 2021-Jan 2022",
      highlights: [
        "Led the initiation, design and development of a freelance marketplace utilizing C#, Asp.net core, jQuery, MSSQL, Twilio, SendGrid.",
        "Led end to end feature development, handling requirement analysis, estimation, and communication with key stakeholders.",
        "Initiated strategic optimizations in response to a surge in user activity, achieving an average of 40% increase in application performance",
        "Created and developed a scalable notification system that facilitated the addition of 15+ notification types, resulting in a 30% reduction in development time compared to the original estimation.",
        "Provided production support services by effectively resolving technical issues, reducing downtime, and ensuring seamless operations."
      ],
      technologies:
        "C#, ASP.NET Core, JavaScript, jQuery, MS SQL, Twilio, SendGrid",
    },

    {
      title: "Software Engineer",
      company: "Spiralogics International",
      Duration: "Nov 2019-Nov 2020",
      highlights: [
        "Set up configuration-driven foundation for n-tier multi-tenant application, ensuring data security at both application and database layer using EF Core and MSSQL RLS.",
        "Setup Jenkins, Redmine, SVN Server and Git on test servers with integration between them to demonstrate a CI/CD centric workflow for agile teams to the developers; culminated with the company adopting Azure DevOps.",
        "Engineered reusable startup project with user, roles and claims management, validation, and logging modules with clean architecture. Used C#, Asp.net Core, Entity Framework, and ASP.NET Identity reducing 1 month worth of development work for new projects.",
        "Worked with QA Engineers to ensure product’s resonance with expected functional and non-functional requirements through end-to-end testing, regression testing, stress testing, security testing, and benchmarking.",
        "Successfully oversaw three teams that were simultaneously working on shared codebase resulting in efficient collaboration and on-time delivery of all projects. Used React, Azure functions and WebAPI hosted in Azure App Service.",
      ],
      technologies:
        "C#, ASP .NET Core, JavaScript, React, Redux, MS SQL, SVN, Git, Oauth2.0, MSAL, Azure Functions, Azure AD",
    },
    {
      title: "Associate Software Engineer",
      company: "Spiralogics International",
      Duration: "Jan 2018-Nov 2019",
      highlights: [
        "Involved in end-to-end development of 4 projects built in C#, Asp.net core, MSSQL, DevExpress, jQuery from inception through delivery. ",
        "Designed and developed HTML-based report generation and editing system, enabling export to DOCX and PDF as per reporting needs to replace a hard-coded docx based system.",
        "Proposed, initiated, and completed the first React application in the company, which became the foundation for 2 additional projects.",
        "Implemented email notification feature using razor pages; Integrated HangFire for background email jobs reducing wait time for user actions from 7 seconds to under 2 seconds.",
        "Set up automatic deployment reducing total deployment time from 1 hour to 3 minutes.",
      ],
      technologies:
        "C#, ASP.NET Core, JavaScript, React, MS SQL, SVN, Git",
    },
    {
      title: "Trainee",
      company: "Young Innovations",
      duration: "Jul 2017 - Nov 2022",
      highlights: [
        "Developed features for administrative section.",
        "Created endpoints for data collection in mobile application.",
        "Implemented automated form update for mobile application.",
      ],
      technologies: "PHP, Laravel, PostgreSQL, jQuery",
    },
    {
      title: "Intern",
      duration: "Apr 2017 - Jun 2022",
      company: "Young Innovations",
      technologies: "PHP, Laravel, PostgreSQL, jQuery",
    },
  ],
  education: [
    {
      level: "High School",
      school: "Kathmandu Model School",
      location: "Bagbazaar, Kathmandu",
      subject: "Management",
    },
    {
      level: "BSc. (Hons)",
      school: "Islington College (LMU)",
      location: "Kamalpokhari, Kathmandu",
      subject: "Computing",
    },
    {
      level: "MS",
      school: "Maharishi International University",
      location: "Fairfield, Iowa",
      subject: "Computer Science",
    },
  ],
  skills: [
    { key: "Languages", value: "C#, Java" },
    {
      key: "Web",
      value: "JavaScript, React, Redux, jQuery, HTML5, CSS3, Bootstrap",
    },
    { key: "Web Services", value: "REST" },
    {
      key: "Web/App Servers",
      value: "IIS, Windows Server, Azure (Functions, App Service)",
    },
    { key: "Frameworks", value: "ASP .NET Core, Spring Boot, Identity Server" },
    { key: "Databases", value: "MS SQL, MySQL, PostgreSQL" },
    {
      key: "Design Patterns",
      value:
        "Template, Strategy, IoC, Singleton, Repository, Webhooks, Mediator, Specification",
    },
    { key: "SDLC", value: "Agile-Scrum" },
    {
      key: "Tools",
      value:
        "Visual Studio, IntelliJ, VS Code, Resharper, Cmder, Git, SVN, GitHub, GitLab, Twilio, SendGrid, ",
    },
    { key: "Platforms", value: "Windows" },
    { key: "Others", value: "Public Speaking" },
  ],
};

export const skills = data.skills;
export const education = data.education;
export const experience = data.experience;
export const projects = data.projects;
